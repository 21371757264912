import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

const navs = document.querySelectorAll('.head-nav-item');
const menu = document.querySelector(".mobile_menu");
const menu_btn = document.querySelector(".g-mobile_menu");
navs.forEach(elem => elem.addEventListener('click', (e) => {
  e.preventDefault();
  const el = elem?.dataset?.nav;

  const navEl = document.querySelector('.nav_block[data-nav="'+el+'"]');
  scrollToSmoothly(navEl.offsetTop - 80);
  
  if (menu.classList.contains("show")) {
    menu.classList.remove('show');
    menu_btn.classList.remove('open');
  }
}));

const scrollToSmoothly = (pos, time) => {
  var currentPos = window.pageYOffset;
  var start = null;
  if(time == null) time = 500;
  pos = +pos, time = +time;
  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    var progress = currentTime - start;
    if (currentPos < pos) {
      window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
    } else {
      window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
    }
    if (progress < time) {
      window.requestAnimationFrame(step);
    } else {
      window.scrollTo(0, pos);
    }
  });
}

const calcCases = () => {
  const casesList = document.querySelectorAll('.cases .case_item');

  let wrapWidth = 1200;
  if ( window.innerWidth < 1230 ) {
    wrapWidth = 990;
  }
  if ( window.innerWidth < 1030 ) {
    wrapWidth = 770;
  }
  if ( window.innerWidth < 799 ) {
    wrapWidth = window.innerWidth - 20;
  }

  const casesPadding = (window.innerWidth - wrapWidth) / 2 - 10;
  casesList[0].style.marginLeft = casesPadding + 'px';
  casesList[casesList.length - 1].style.marginRight = casesPadding + 'px';
};

calcCases();

window.onresize = () => {
  calcCases();
}

const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if ( entry.isIntersecting ) {
      const nav = entry?.target?.dataset?.nav;
      document.querySelectorAll('.header_nav-item.active').forEach(item => item.classList.remove('active'));
      document.querySelectorAll('.header_nav-item[data-nav="'+nav+'"]').forEach(item => item.classList.add('active'));
    }
  });
}, {
  threshold: 0.6,
});

const navElems = document.querySelectorAll('.nav_block');
navElems.forEach(el => {
  observer.observe(el);
});

menu_btn.addEventListener("click", (e) => {
  e.preventDefault();

  if (menu.classList.contains("show")) {
    menu.classList.remove('show');
    menu_btn.classList.remove('open');
  } else {
    menu.classList.add('show');
    menu_btn.classList.add('open');
  }
});

const scrollTrack = document.querySelector(".cases .list-outer");
document.querySelectorAll(".case__scroll").forEach(_el => {
  _el.addEventListener("click", (e) => {
    e.preventDefault();

    if ( _el.classList.contains("left") ) {
      scrollTrack.scrollLeft -= 300;
    } else {
      scrollTrack.scrollLeft += 300;
    }
  });
});

document.querySelectorAll(".faq li h4").forEach(el => {
  el.addEventListener('click', () => {
    el.parentNode.classList.toggle('open');
  });
});

document.querySelectorAll(".foot-languages select").forEach(el => {
  el.addEventListener('change', e => {
    window.location.pathname = e.target.value;
  });
});

let rootPhone;
let model1Load = false;
let model2Load = false;

const canvasW =  window.innerWidth > 1030 ? 454 : 342;
const canvasH = window.innerWidth > 1030 ? 658 : 448;

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera( 11, canvasW / canvasH, 0.1, 2000 );

const renderer = new THREE.WebGLRenderer({ alpha: true });//
renderer.setSize( canvasW, canvasH );
renderer.setPixelRatio( window.devicePixelRatio );
renderer.toneMapping = THREE.ReinhardToneMapping;
renderer.toneMappingExposure = 2.4;
document.querySelector(".intro .char").appendChild( renderer.domElement );

renderer.shadowMap.enabled = true;

const light1 = new THREE.AmbientLight( 0xFFFFFF, 1 );
scene.add( light1 );

const pointLight = new THREE.PointLight( 0x3278F5, 0.6, 100 );
pointLight.position.set( 2, 2, 2 );
pointLight.castShadow = true;
scene.add( pointLight );

const pointLight2 = new THREE.PointLight( 0xffffff, 1, 100 );
pointLight2.position.set( -2, -2, -2 );
pointLight2.castShadow = true;
scene.add( pointLight2 );

const pointLight3 = new THREE.PointLight( 0xffffff, 0.4, 100 );
pointLight3.position.set( -2, 2, 2 );
pointLight3.castShadow = true;
scene.add( pointLight3 );

const directionalLight = new THREE.DirectionalLight( 0xffffff, 1 );
directionalLight.position.set(1, 1, 1);
directionalLight.castShadow = true;

scene.add( directionalLight );

const directionalLight1 = new THREE.DirectionalLight( 0xffffff, 1 );
directionalLight1.position.set(-1, 1, -1);
directionalLight1.castShadow = true;
scene.add( directionalLight1 );

const directionalLight2 = new THREE.DirectionalLight( 0xffffff, 1 );
directionalLight2.position.set(-1, 0.5, 1);
directionalLight2.castShadow = true;
scene.add( directionalLight2 );

camera.position.set(-3,1,9);

const controls = new OrbitControls( camera, renderer.domElement );

controls.enableZoom = false;
controls.enablePan = false;
controls.enableDamping = true;

controls.minPolarAngle = 1.16;
controls.maxPolarAngle = 1.76;

controls.minAzimuthAngle = -0.62;
controls.maxAzimuthAngle = -0.02;

controls.update();

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath('/draco/');

const loader = new GLTFLoader();
loader.setDRACOLoader(dracoLoader);

if ( window.innerWidth > 799 ) {
  loader.load(
    '/models/hand.glb',
    ( gltf ) => {
      model1Load = true;
      const rootHand = gltf.scene;

      rootHand.scale.set(1.1,1.1,1.1);
      rootHand.position.set(0,-1.2,0.4);
      rootHand.rotateX(Math.PI / 180 * -35);
      rootHand.rotateZ(Math.PI / 180 * 15);
      rootHand.rotateY(Math.PI / 180 * 35);

      rootHand.traverse(function (child) {
        if (child.isMesh) {
          child.receiveShadow = true;
        }
      });

      scene.add( rootHand );

      if ( model2Load ) {
        document.querySelector(".intro .char").classList.add("loaded");
      }
    }, (progress) => {
      console.log(progress);
    }, ( error ) => {
      console.error( error );
    }
  );

  loader.load(
    '/models/scene.glb',
    ( gltf ) => {
      model2Load = true;
      rootPhone = gltf.scene;
      rootPhone.rotateY(Math.PI / 180 * 180);
      rootPhone.rotateX(Math.PI / 180 * 15);
      //rootPhone.position.set(0,0,0);

      rootPhone.shadowCameraVisible = true;
      rootPhone.castShadow = true;

      rootPhone.traverse(function (child) {
        if (child.isMesh) {
          child.castShadow = true;
        }
      });

      scene.add( rootPhone );

      if ( model1Load ) {
        document.querySelector(".intro .char").classList.add("loaded");
      }
    }, (progress) => {
      console.log(progress);
    }, ( error ) => {
      console.error( error );
    }
  );

  let direction = "right";
  const animStep = 0.005;

  function animate() {
    requestAnimationFrame( animate );

    if ( rootPhone ) {
      if (direction === "right" && rootPhone.rotation.y < 1.1) {
        const nextValue = rootPhone.rotation.y + animStep;
        rootPhone.rotation.y = nextValue;
        if ( nextValue >= 1.1 ) {
          direction = "left";
        }
      } else if (direction === "left" && rootPhone.rotation.y > -0.5) {
        const nextValue = rootPhone.rotation.y - animStep;
        rootPhone.rotation.y = nextValue;
        if ( nextValue <= -0.5 ) {
          direction = "right";
        }
      }
    }

    renderer.render( scene, camera );
  };

  animate();
}